import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import axios from "axios";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboards"],
        },
      },
      {
        path: "/niches",
        name: "Niches",
        component: () => import("@/views/niche/NicheList.vue"),
        meta: {
          middlewares: ["admin","niche_manager"],
          pageTitle: "Niches",
          breadcrumbs: ["Niches"],
        },
      },
      {
        path: "/affiliate-networks",
        name: "AffiliateNetworks",
        component: () => import("@/views/affiliate-network/AffiliateNetworkList.vue"),
        meta: {
          middlewares: ["admin","affiliate_network_manager"],
          pageTitle: "Affiliate Networks",
          breadcrumbs: ["Affiliate Networks"],
        },
      },
      {
        path: "/offers",
        name: "Offers",
        component: () => import("@/views/offer/OfferList.vue"),
        meta: {
          middlewares: ["admin","offer_manager"],
          pageTitle: "Offers",
          breadcrumbs: ["Offers"],
        },
      },
      {
        path: '/offers/:offer_id',
        name: "offers.details",
        component: () => import("@/views/offer/OfferDetails.vue"),
        meta: { 
          middlewares: ["admin","offer_manager"],
          pageTitle: "Offer Details",
          breadcrumbs: ["Offers"],
        },
      },

      {
        path: '/offers/:offer_id/body-contents/:content_id/preview',
        name: "content.preview",
        component: () => import("@/views/bodyContent/preview.vue"),
        meta: { 
          middlewares: ["admin","offer_manager"],
          pageTitle: "Body Content Preview",
          breadcrumbs: ["Body Content Preview"],
        },
      },
      {
        path: '/queues/offers/',
        name: "queues.offers",
        component: () => import("@/views/queues/offers/OfferQueueList.vue"),
        meta: { 
          middlewares: ["admin","queue_manager"],
          pageTitle: "Offer Queus",
          breadcrumbs: ["Offer Queues"],
        },
      },
      {
        path: '/queues/offers/:offer_id',
        name: "queues.offers.details",
        component: () => import("@/views/queues/offers/OfferDetailsQueueList.vue"),
        meta: { 
          middlewares: ["admin","queue_manager"],
          pageTitle: "Offer Details Queus",
          breadcrumbs: ["Offers" ,"Offer Details Queues"],
        },
      },
      {
        path: '/users',
        name: "users.index",
        component: () => import("@/views/users/userList.vue"),
        meta: { 
          middlewares: ["admin","user_manager"],
          pageTitle: "Users",
          breadcrumbs: ["Users"],
        },
      },
      {
        path: '/landingPages',
        name: "landing_pages.index",
        component: () => import("@/views/landingPages/landingPagesList.vue"),
        meta: { 
          middleware: "landing_page_manager",
          pageTitle: "Landing pages",
          breadcrumbs: ["Landing Pages"],
        },
      },
      {
        path: '/landingPages/models',
        name: "landing_pages.models",
        component: () => import("@/views/landingPageModels/landingPageModelsList.vue"),
        meta: { 
          middleware: "page_model_manager",
          pageTitle: "Landing page Models",
          breadcrumbs: ["Landing Page Models"],
        },
      },
      {
        path: '/brands',
        name: "brands.index",
        component: () => import("@/views/Brands/BrandsList.vue"),
        meta: { 
          middleware: "brand_manager",
          pageTitle: "Brands",
          breadcrumbs: ["Brands"],
        },
      }
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: "Sign Up",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // verify auth token before each page change
  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // before page access check if page requires authentication
  if (to.meta.middleware == "auth") {

    
    if (store.getters.isUserAuthenticated) {

      next();
    } else {
      next({ name: "sign-in" });
    }

  }else if( to.meta.middleware ){

    const roles_response  = await axios.get('users/roles');
    const roles           = roles_response.data.user_roles;

   
    if( roles.includes(to.meta.middleware)  || store.getters.currentUser.is_admin){
      next();
    }
    else{
      next({ name: '404' })
    }

  } else {
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
